import React, { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { CourseHttpService } from "../../../../../services/course";
import Done from "../../shared/Done";
import Error from "../../shared/Error";
import Loading from "../../shared/Loading";
import { Edit } from "@mui/icons-material";
import NamesWidgets from "../shared/NamesWidgets";
import ImageFieldWithGallery from "../../Widgets/shared/ImageFieldWithGallery";
import SelectPeopleType from "view/cms/features/feature_blog/components/forms/SelectPeopleType";
import { Utils } from "utils";

function InstructorForm({ id, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [image, setImage] = useState(null);
  const [names, setNames] = useState([]);
  const [namesRefresh, setNamesRefresh] = useState(0);
  
  
  const fileRef = useRef()

  useEffect(() => {
    if (id) {
      CourseHttpService.loadInstructor(id).then(item => {
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)

    for (const key in item) {
      setValue(key, item[key])
    }

    setNames(item.names)
    setNamesRefresh(namesRefresh + 1)

    setImage(item.image)

  }

  const handleValue = (value, name) => {
    switch (name) {

      case "type":
        setValue("type", value)
        break;
      default:
        break;
    }
  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    if(!data.slug){
      setError("Please provide a slug")
      return;
    }

    var slug = Utils.slugify(data.slug)

    var body = {
      "id": id ? id : data.id,
      names: names,
      slug: slug,
      intro_url : data.intro_url,
      type : Array.isArray(data.type) ? data.type.join(',') : data.type,
      "image" : image,
      "sort_order" : data.sort_order ?? 0
    }

    setDone(false)
    setLoading(true)
    CourseHttpService.saveInstructor(body).then(response => {
      console.log(response)
      setLoading(false)
      setDone(true)
      setError(false)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };

  

  return (
    <div>
      

      <form className="" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <NamesWidgets key={namesRefresh + "nms"}  show_short_description={1} names={names} setNames={(names) => {  setNames(names) }} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <ImageFieldWithGallery type="people" onImageSelected={setImage} defaultImageUrl={image} key={image+"imgg"} />
            </div>
            <div className="form-group">
              <TextField
                fullWidth
                label="Unique Url"
                value={watch('slug') ?? ""}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("slug")}
              />
            </div>
            <div className="form-group">
              <TextField
                fullWidth
                number="true"
                label="Introduction Video Url"
                value={watch('intro_url') ?? ""}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("intro_url")}
              />
            </div>
            <div className="form-group">
              <TextField
                fullWidth
                label="Sort Order"
                value={watch('sort_order') ?? ""}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("sort_order")}
              />
            </div>

            <div className="form-group">
                <SelectPeopleType placeholder="Type" width={"100%"} callback={handleValue} value={watch("type") ?? "instructor"} border={"1"} />
            </div>
          </div>
        </div>
        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <Edit /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add Instructor"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <Loading />}
        </div>
      </form>
    </div>
  );
}

export default InstructorForm;
