import { CircularProgress, TextField } from '@mui/material';
import React, { useRef, useState } from 'react';
import { CourseHttpService } from 'services/course';
import Done from 'view/cms/components/shared/Done';
import Error from 'view/cms/components/shared/Error';
import LrcList from './LrcList';
import Tracks from './Tracks';
import { useForm } from 'react-hook-form';

function ContentCreateTrack({ content }) {

    const { register, watch } = useForm();


    const [isPlaying, setIsPlaying] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showLrcEditor, setShowLrcEditor] = useState(false);
    const [lrcText, setLrcText] = useState('');
    const [vttText, setVttText] = useState('');
    const [language, setLanguage] = useState(null);
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const [lrcData, setLrcData] = useState([]);
    const [showSaveStep, setShowSaveStep] = useState(false);
    
    const lrcTextContainerRef = useRef(null);
    const videoRef = useRef(null);

    const pause = () => {
        setIsPlaying(false);
        videoRef.current.pause();
    }

    const play = () => {
        setIsPlaying(true);
        videoRef.current.play();
    }

    const handleAddLine = () => {
        const currentText = document.getElementById('lrctext').value;
        const lines = currentText.split('\n');
        const currentTime = videoRef.current.currentTime;
        const nextLine = lines[lrcData.length];
        const nextIndex = lrcData.length;

        if (nextLine !== undefined) {
            const newLrcData = [...lrcData, { time: currentTime, text: nextLine, index: nextIndex }];
            setLrcData(newLrcData);
            setTimeout(() => {
                scrollToLastBoldLine();
            }, 0);
        }
    };

    const scrollToLastBoldLine = () => {
        const container = lrcTextContainerRef.current;
        if (container) {
            const boldElements = container.querySelectorAll('span[style*="bold"]');
            const lastBoldElement = boldElements[boldElements.length - 1];
            if (lastBoldElement) {
                container.scrollTop = lastBoldElement.offsetTop - 300;
            }
        }
    };

    const playPause = () => {
        if (videoRef.current.paused) {
            play();
        } else {
            pause();
        }
    };

    const handleNext = () => {
        setShowLrcEditor(true);
        setLrcText(document.getElementById('lrctext').value);
    };

    const handleFinish = () => {
        renderVttText();
        setShowSaveStep(true);
    };

    const renderTextWithLineBreaks = () => {
        const currentText = document.getElementById('lrctext')?.value ?? "";
        const lines = currentText.split('\n');
        return lines.map((line, index) => {
            const isLineSaved = lrcData.some(lrc => lrc.text === line && lrc.index === index);
            return (
                <React.Fragment key={index}>
                    <span style={{ fontWeight: isLineSaved ? 'bold' : 'normal' }}>{line}</span>
                    <br />
                </React.Fragment>
            );
        });
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.000`;
    };

    const renderVttText = () => {
        const vtt = lrcData.map((lrc, index) => {
            const startTime = formatTime(lrc.time);
            let endTime;
    
            if (index < lrcData.length - 1) {
                // Calculate the next line's start time minus 0.3 seconds
                const nextStartTime = lrcData[index + 1].time;
                endTime = nextStartTime - lrc.time < 5 
                    ? formatTime(nextStartTime - 0.3) 
                    : formatTime(lrc.time + 5);
            } else {
                // Last line: set end time to 5 seconds from start
                endTime = formatTime(lrc.time + 5);
            }
    
            return `${startTime} --> ${endTime}\n${lrc.text}`;
        }).join('\n\n');
    
        setVttText(`WEBVTT\n\n${vtt}`);
    };
    

    const handleValue = (value, name) => {
        if (name === "language") setLanguage(value);
    }

    const saveChanges = async () => {
        setError(false);
    
        const label = watch("label");
        const srcLang = watch("src_lang");
    
        if (!label || !srcLang) {
            setError("Please provide both a label and language code");
            return;
        }
    
        const vttBlob = new Blob([vttText], { type: 'text/vtt' });
        const file = new File([vttBlob], 'track.vtt', { type: 'text/vtt' });
    
        const body = {
            content: content.id,
            label,
            src_lang: srcLang,
            is_default: true,
            file
        };

        console.log(body)
    
        setDone(false);
        setLoading(true);
        try {
            const response = await CourseHttpService.saveTrack(body);
            setLoading(false);
            setDone(true);
            setRefresh(refresh + 1);
        } catch (err) {
            setLoading(false);
            setError("Something went wrong");
        }
    };
    

    return (
        <div className="col-lg-12">
            <div className="flex-align-items-center flex-space-between mb-3">
                <h2 className='mb-0'>Track</h2>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <video style={{ width: "100%" }} id="transcript_video" poster={content?.image} ref={videoRef} controls>
                        <source src={content?.url ?? "/assets/video/kt.mp4"} type="video/mp4" />
                    </video>
                </div>
                <div className="col-md-8">
                    <div className={`lrc-text-area ${showLrcEditor ? 'hide' : ''}`}>
                        <textarea className="form-control mb-3 kfont" id="lrctext" rows="8"></textarea>
                        <button className="btn btn-primary" onClick={handleNext}>
                            Next
                        </button>
                    </div>

                    <div className={`lrceditor ${showLrcEditor ? '' : 'hide'} ${showSaveStep ? 'hide' : ''}`}>
                        <div className="lrc-text-container kfont" ref={lrcTextContainerRef}>
                            {renderTextWithLineBreaks(lrcText)}
                        </div>
                        <div className="flex-align-items-center flex-space-between mb-3">
                            <div className='flex-align-items-center'>
                                <button className="btn btn-primary" onClick={() => { if (window.confirm('are you sure?')) { setLrcData([]); setShowLrcEditor(false) } }}>
                                    Edit text
                                </button>
                                <button className="btn btn-primary" title='play/pause video' onClick={playPause}>
                                    <i className={`fas ${isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
                                </button>
                                <button className="btn btn-primary" onClick={handleAddLine}>
                                    Next line
                                </button>
                                <button className="btn btn-primary" onClick={handleFinish}>
                                    Finish
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className={`lrc-text-area ${showSaveStep ? '' : 'hide'}`}>
                        <textarea className="form-control mb-3 kfont" id="lrcwithtimes" value={vttText} readOnly rows="8"></textarea>

                        {done && <Done />}
                        {error && <Error message={error} />}

                        <div className="d-flex flex-gap-3">
                            <TextField
                                fullWidth
                                label="Label (e.g., English)"
                                value={watch('label') ?? ""}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                {...register("label")}
                            />
                            <TextField
                                fullWidth
                                label="Language code (e.g., en, ku, fr)"
                                value={watch('src_lang') ?? ""}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                {...register("src_lang")}
                            />
                            <button className="btn btn-primary" onClick={saveChanges} >
                                Save Track
                            </button>
                            {loading && <CircularProgress color="inherit" size={30} />}
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-5">
                <Tracks contentId={content?.id} />
            </div>
        </div>
    );
}

export default ContentCreateTrack;
