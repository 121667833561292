import { Alert } from '@mui/material'
import React from 'react'

function NoPermission() {
  return (
    <Alert className='mt-3 mb-3' severity="error">
        You do not have permission to view this page.                       
    </Alert>
  )
}

export default NoPermission