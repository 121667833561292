import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import TranslateController from 'controller/shared/TranslateController';
import { Utils } from 'utils';
import AdminView, { EditorView } from './Permissions';
import TopMenu from './TopMenu';

function CmsHeader({ transparent = false }) {


	return (
		<header id="header" className={`${transparent ? "header-transparent header-effect-shrink" : ""} bg-dark mb-header `} data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyChangeLogo': true, 'stickyStartAt': 30, 'stickyHeaderContainerHeight': 70}">
			<div className="header-body border-top-0 bg-black box-shadow-none h-auto">
				<div className="header-container container">
					<div className="header-row">
						<div className="header-column">
							<div className="header-row">
								<div className="header-logo">
									<a href="/cms/dashboard">
										<img alt="Porto" width="82" height="40" src="/assets/images/logo.png" />
									</a>
								</div>
							</div>
						</div>
						<div className="header-column justify-content-end">
							<div className="header-row">
								<div className="header-nav header-nav-links header-nav-dropdowns-dark header-nav-light-text order-2 order-lg-1">
									<TopMenu />
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</header>
	)
}

export default CmsHeader