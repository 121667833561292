import React from "react";
import Select from "react-select";

export default function SelectStyleSize({
  callback,
  value,
  placeholder = "Select Size",
  width,
  name = "style_size",
}) {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "white",
      width: width ? width : "200px",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {},
    }),
    menu: (base) => ({
      ...base,
      width: "200px",
      "*::-webkit-scrollbar": {
        width: "0.4em",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "#c7c7c7",
      },
      borderRadius: 0,
      borderBottomRightRadius: 5,
      borderBottomLeftRadius: 5,
      marginTop: 0,
      backgroundColor: "#fff",
      opacity: "0.97",
    }),
    menuList: (base) => ({
      ...base,
      padding: 10,
      "&:hover": {
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5,
        transition: "all 0.5s ease-out",
      },
    }),
    option: (base, state) => {
      let backgroundColor = "transparent";
      if (state.isSelected || state.isFocused || state.isActive) {
        backgroundColor = "#ccc";
      }
      return {
        ...base,
        color: "#000",
        backgroundColor,
        transition: "all 0.5s ease-out",
      };
    },
  };

  // Options for sizes 1 through 12
  const options = Array.from({ length: 12 }, (_, i) => ({
    label: `${i + 1}`,
    value: i + 1,
  }));

  const handleSelect = (e) => {
    const value = e.value;
    callback(value, name);
  };

  return (
    <div className="filter-select">
      <Select
        options={options}
        styles={customStyles}
        onChange={handleSelect}
        placeholder={placeholder}
        value={options && options.find((obj) => obj.value === value)}
      />
    </div>
  );
}
