import React, { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { CourseHttpService } from "../../../../../services/course";
import Done from "../../shared/Done";
import Error from "../../shared/Error";
import Loading from "../../shared/Loading";
import { Edit } from "@mui/icons-material";
import NamesWidgets from "../shared/NamesWidgets";
import Gallery from "../../Widgets/shared/Gallery";
import ImageFieldWithGallery from "../../Widgets/shared/ImageFieldWithGallery";
import SelectLevel from "./selects/SelectLevel";
import SelectCategory from "view/cms/features/feature_blog/components/forms/SelectCategory";
import SelectPeople from "view/cms/features/feature_blog/components/forms/SelectPeople";
import SelectPeopleMulti from "view/cms/features/feature_blog/components/forms/SelectPeopleMulti";

function PodcastForm({ id, week, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [namesRefresh, setNamesRefresh] = useState(0);
  const [course_image, setCourseImage] = useState(null);
  const [names, setNames] = useState([]);


  useEffect(() => {
    if (id) {
      CourseHttpService.loadPodcast(id).then(item => {
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)


    for (const key in item) {
      setValue(key, item[key])
    }

    if(item.category){
      setValue("category",item.category?.id)
    }

    if(item.narrators){
      let narratorIds = item.narrators.map(narrator => narrator.id)
      setValue("narrators",narratorIds)
    }

    if(item.writers){
      let writerIds = item.writers.map(writer => writer.id)
      setValue("writers",writerIds)
    }


    if(item.level){
      setValue("level",item.level?.id)
    }

    setNames(item.names)
    setNamesRefresh(namesRefresh + 1)
    setCourseImage(item.image)

  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;



    var body = {
      "id": id ? id : data.id,
      names: names,
      "level": data.level,
      "category": data.category,
      "writers": data.writers,
      "narrators": data.narrators,
      "image": course_image
    }



    setDone(false)
    setLoading(true)
    CourseHttpService.savePodcast(body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };


  const handleValue = (value, name) => {
    switch (name) {

      case "category":
        setValue("category", value)
        break;
      case "level":
        setValue("level", value)
        break;
      case "writers":
        setValue("writers", value)
        break;
      case "narrators":
        setValue("narrators", value)
        break;
      default:
        break;
    }
  }



  return (
    <div>


      <form className="" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <NamesWidgets show_short_description={1} key={namesRefresh + "nms"} names={names} setNames={(names) => { setNames(names) }} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <ImageFieldWithGallery onImageSelected={setCourseImage} defaultImageUrl={course_image} key={course_image + "imgg"} />
            </div>
            {/* <div className="form-group">
              <SelectCategory width={"100%"}  name="category" placeholder="Categoty"  callback={handleValue} value={watch("category")} border={"1"} />
            </div> */}
            <div className="form-group">
              <SelectLevel   width={"100%"} callback={handleValue} value={watch("level")} border={"1"} />
            </div>
            <div className="form-group">
              <SelectPeopleMulti type="writer" name="writers" width={"100%"} placeholder="Podcast Writer" callback={handleValue} value={watch("writers")} border={"1"} />
            </div>
            <div className="form-group">
              <SelectPeopleMulti type="narrator" name="narrators" width={"100%"} placeholder="Podcast Narrator" callback={handleValue} value={watch("narrators")} border={"1"} />
            </div>
          </div>
        </div>
        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <Edit /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add Podcast"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <Loading />}
        </div>
      </form>
    </div>
  );
}

export default PodcastForm;
