import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Utils } from 'utils';
import { PostHttpService } from 'view/cms/features/feature_blog/services';

function BlogStyle3({ title = "Popular posts" , category , categoryId , size=10 }) {

    const [posts, setData] = useState(null);

    const { isLoading, error, data, refetch } = useQuery(['posts_popular',size,categoryId], () => { return PostHttpService.loadPosts(categoryId,"",size) }, {
        onSuccess: (data) => {
            setData(data.results)
        },
        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
        refetchInterval: 60 * 100000,
    });


    return (
        <div className=''>
            <h3 className="font-weight-bold text-3 mb-0 lan-rtl lan-text-right">{category ? Utils.getDefaultName(category) :title}</h3>

            <ul className="simple-post-list lan-rtl">
                {posts && posts.map(post => (
                    <li key={"post_st3_"+post.id}>
                        <article>
                            <div className="post-image lan-float-right">
                                <div className="img-thumbnail img-thumbnail-no-borders d-block">
                                    <Link to={`/post/${post.id}`}>
                                        <img src={post.image} className="border-radius-0" width="50" height="50" alt={post.title} />
                                    </Link>
                                </div>
                            </div>
                            <div className="post-info">
                                {/* <div className="post-meta">
                                    {Utils.formatDate(post.created_at)}
                                </div> */}
                                <h4 className="font-weight-normal text-3 mb-0"> <Link to={Utils.getPostUrl(post)} className="text-dark">{post.title}</Link></h4>
                            </div>
                        </article>
                    </li>
                )
                )}


            </ul>
        </div>
    )
}

export default BlogStyle3