import React from 'react';
import Select from 'react-select';

export default function SelectPeopleType({ callback, value, placeholder="Type", width, name="type" }) {

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "white",
            width: width ? width : "200px",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {}
        }),
        menu: base => ({
            ...base,
            width:"200px",
            '*::-webkit-scrollbar': {
                width: '0.4em'
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: '#c7c7c7',
            },
            borderRadius: 0,
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            marginTop: 0,
            backgroundColor: "#fff",
            opacity: "0.97"
        }),
        menuList: base => ({
            ...base,
            padding: 10,
            "&:hover": {
                borderBottomRightRadius: 5,
                borderBottomLeftRadius: 5,
                transition: "all 0.5s ease-out"
            }
        }),
        option: (base, state) => {
            let backgroundColor = "transparent";
            if (state.isSelected) {
                backgroundColor = "#ccc";
            }
            if (state.isFocused) {
                backgroundColor = "#ccc";
            }
            if (state.isActive) {
                backgroundColor = "#ccc";
            }
            return {
                ...base,
                color: "#000",
                backgroundColor,
                transition: "all 0.5s ease-out"
            };
        }
    };

    const options = [
        { label: "Singer", value: "artist" },
        { label: "Musician", value: "musician" },
        { label: "Course Instructor", value: "instructor" },
        { label: "Transcriber", value: "transcriber" },
        { label: "Author", value: "author" },
        { label: "Podcast Writer", value: "writer" },
        { label: "Narrator", value: "narrator" },
        { label: "Poet", value: "poet" },
    ];

    const handleSelect = (selectedOptions) => {
        const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
        callback(values, name);
    }

    return (
        <div className="filter-select">
            <Select
                options={options}
                styles={customStyles}
                onChange={handleSelect}
                placeholder={placeholder}
                value={options.filter(option => value.includes(option.value))}
                isMulti
            />
        </div>
    );
}
