import { createContext, useEffect, useState } from "react";
// import { BaseHttpService } from "../services/base";
import { HttpService } from "../services/http";
import { Utils } from "../utils";
import { BaseHttpService } from "services/base";

export const UserContext = createContext();

export const UserContextProvider = (props) => {

  const [authenticate, setAuthenticate] = useState();
  const [profile, setProfile] = useState();
  const [userLoading, setUserLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);
  const signout = urlParams.get('signout')

  if (signout) {
    HttpService.signOut();
    setAuthenticate(null) // why this is not working ?

    //removing the ?signout=1 from the url
    Utils.clearUrl()
  }

  const hasPermission = (text) => {
    if(profile && profile.role=='admin') return true;

    return permissions.includes(text);
  };

  const _getMe = () => {
    setUserLoading(true)
    BaseHttpService.loadMe().then(profile => {
      setProfile(profile)
      if(profile.permission){
        setPermissions(profile.permission.permissions)
      }
      setUserLoading(false)
    })

  }


  let token = window.localStorage.getItem(HttpService.TOKEN_KEY);
  useEffect(() => {

    if (token) {
      _getMe()
    }

  }, []);

  return (
    <UserContext.Provider value={{ profile , userLoading , hasPermission }}>
      {props.children}
    </UserContext.Provider>
  );
};
