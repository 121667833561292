import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { CourseHttpService } from 'services/course';
import { useQuery } from 'react-query';
import { HttpService } from 'services/http';
import { Utils } from 'utils';
import UserStatisticsChart from './UserStatisticsChart';
import TopProgressedUsersWidget from './TopProgressedUsersWidget';
import GenderStatisticsChart from './GenderStatisticsChart';
import NewestEnrolledUsersWidget from './NewestEnrolledUsersWidget';
import { useNavigate } from 'react-router-dom';
import TopOnlineUsersWidget from './TopOnlineUsersWidget';
import CMSUserTabs from './CMSUserTabs';

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
var courseIds

const ProgressTotalTable = () => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    const [overallStats, setOverallStats] = useState({
        totalEnrollment: 0,
        totalCompletion: 0,
        averageProgress: 0,
    });

    const navigate = useNavigate(); // Initialize the useNavigate hook



    const { isLoading, error, data, refetch } = useQuery('course-statistics', () => {
        return CourseHttpService.loadCourseStatistics();
    }, {
        onSuccess: (data) => {
            const courseNames = data.map((item) => Utils.getDefaultName(item.course));
            courseIds = data.map((item) => item.course.id);
            const enrollmentCounts = data.map((item) => item.enrollment_count);
            const completionCounts = data.map((item) => item.completion_count);
            const averageProgress = data.map((item) => item.average_progress);

            // Calculate overall stats
            var totalEnrollment = enrollmentCounts.reduce((a, b) => a + b, 0);
            var totalCompletion = completionCounts.reduce((a, b) => a + b, 0);
            var averageProgressTotal = averageProgress.reduce((a, b) => a + b, 0) / averageProgress.length;

            if(data && data.length>0){
                totalEnrollment = totalEnrollment + data[0].plus.enrollment_count
                totalCompletion = totalCompletion + data[0].plus.completion_count
                averageProgressTotal = averageProgressTotal + data[0].plus.average_progress
            }




            setChartData({
                labels: courseNames,
                datasets: [
                    {
                        label: 'Enrollment Count',
                        data: enrollmentCounts,
                        backgroundColor: 'rgba(54, 162, 235, 0.6)',
                    },
                    {
                        label: 'Completion Count',
                        data: completionCounts,
                        backgroundColor: 'rgba(255, 99, 132, 0.6)',
                    },
                    {
                        label: 'Average Progress',
                        data: averageProgress,
                        backgroundColor: 'rgba(255, 206, 86, 0.6)',
                    },
                ],
            });

            setOverallStats({
                totalEnrollment,
                totalCompletion,
                averageProgress: averageProgressTotal,
            });
        },
        refetchInterval: HttpService.DefaultRefetchInterval,
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading data</div>;
    }

    return (
        <table className="table  mb-3">
            <tbody>
                <tr>
                    <th>Total Enrollment</th>
                    <td>{Utils.numberFormat(overallStats.totalEnrollment)}</td>
                </tr>
                <tr>
                    <th>Total Completion</th>
                    <td>{Utils.numberFormat(overallStats.totalCompletion)}</td>
                </tr>
                <tr>
                    <th>Total Progress</th>
                    <td>{Utils.numberFormat(overallStats.averageProgress.toFixed(2))} %</td>
                </tr>
            </tbody>
        </table>
    );
};

export default ProgressTotalTable;
