import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import AdminView, { EditorView } from './Permissions';
import TranslateController from 'controller/shared/TranslateController';
import { LanguageContext } from 'contexts/LanguageContext';
import CustomModal from 'view/components/modals/CustomModal';
import MegaMenu from './MegaMenu';
import PermissionCheck from './PermissionCheck';

function TopMenu() {

    const { changeLanguage } = useContext(LanguageContext);
    const { _t } = TranslateController();

    const [megaMenuIsOpen, setMegaMenuIsOpen] = useState(false)
    return (
        <>
            <div className="header-nav-main header-nav-main-mobile-dark header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-effect-2 header-nav-main-sub-effect-1">
                <nav className="collapse">
                    <ul className="nav nav-pills" id="mainNav">
                        {/* <li >
                            <Link className="dropdown-item  lan-kfont dropdown-toggle " to="/cms/dashboard">
                                {_t("Dashboard")}
                            </Link>
                        </li> */}
                        <PermissionCheck permission="list_course"  >
                            <EditorView>
                                <li className="dropdown">
                                    <Link to={"/cms/course/"} className="dropdown-item  lan-kfont dropdown-toggle ">
                                        Courses
                                    </Link>
                                    <ul className="dropdown-menu">
                                        <li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/course/`}>Courses</Link></li>
                                        <li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/course/category/`}>Categories</Link></li>
                                        <li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/review/`}>Reviews</Link></li>
                                        <li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/levels/`}>Levels</Link></li>
                                    </ul>
                                </li>
                            </EditorView>
                        </PermissionCheck>
                        <PermissionCheck permission="list_podcast"  >
                            <EditorView>
                                <li className="dropdown">
                                    <Link to={"/cms/podcast/"} className="dropdown-item  lan-kfont dropdown-toggle ">
                                        Podcasts
                                    </Link>
                                    <ul className="dropdown-menu">
                                        <li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/podcast/`}>Podcasts</Link></li>
                                        <li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/podcast/categoty/`}>Categories</Link></li>
                                    </ul>
                                </li>
                            </EditorView>
                        </PermissionCheck>

                        <PermissionCheck permission="list_notation"  >
                            <EditorView>
                                <li className="dropdown">
                                    <Link to={"/cms/notation/"} className="dropdown-item  lan-kfont dropdown-toggle ">
                                        Notation
                                    </Link>
                                    <AdminView>
                                        <ul className="dropdown-menu">
                                            <li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/notation/`}>Notation</Link></li>
                                            <li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/notation/scale/`}>Scales</Link></li>
                                            <li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/notation/time-signature/`}>Time Signatures</Link></li>
                                        </ul>
                                    </AdminView>
                                </li>
                            </EditorView>
                        </PermissionCheck>

                        <PermissionCheck permission="list_article">
                            <EditorView>
                                <li className="dropdown">
                                    <Link to="/cms/posts" className="dropdown-item lan-kfont  lan-kfont dropdown-toggle ">
                                        Articles
                                    </Link>
                                    <ul className="dropdown-menu">
                                        <li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/posts/`}>All Posts</Link></li>
                                        <AdminView>
                                            <li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/category/`}>Categories</Link></li>
                                        </AdminView>
                                        {/* <li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/author/`}>Authors</Link></li> */}
                                    </ul>
                                </li>
                            </EditorView>
                        </PermissionCheck>

                        <PermissionCheck permission="setting">
                        <li className="dropdown">
                            <Link to="/cms/settings" className="dropdown-item lan-kfont">
                                Settings
                            </Link>
                        </li>
                        </PermissionCheck>

                        <li className="dropdown hide-on-small">
                            <Link className="dropdown-item lan-kfont dropdown-toggle lan-kfont">
                                {/* {Utils.getCurrentLanguageNameFull()} */}
                                <i className="fa fa-globe font-size-20 opacity-7"></i> &nbsp;
                            </Link>
                            <ul className="dropdown-menu">
                                <li onClick={(e) => { e.preventDefault(); changeLanguage("so") }} className="kfont"><Link className="dropdown-item lan-kfont" >کوردی</Link></li>
                                <li onClick={(e) => { e.preventDefault(); changeLanguage("kr") }} ><Link className="dropdown-item lan-kfont" >Kurdî</Link></li>
                                <li onClick={(e) => { e.preventDefault(); changeLanguage("en") }} ><Link className="dropdown-item lan-kfont" >English</Link></li>
                            </ul>
                        </li>
                        <AdminView>
                            <li className='hide-on-small' >
                                <a className="" href="" onClick={(e) => { e.preventDefault(); setMegaMenuIsOpen(true) }}  >
                                    <i className="fa fa-bars"></i>
                                </a>
                            </li>
                        </AdminView>
                        <li >
                            <a className="" href="/?signout">
                                <i className="fa fa-sign-out"></i>
                            </a>
                        </li>

                    </ul>
                </nav>
            </div>
            <button className="btn header-btn-collapse-nav" data-bs-toggle="collapse" data-bs-target=".header-nav-main nav">
                <i className="fas fa-bars"></i>
            </button>
            {megaMenuIsOpen && (
                <CustomModal big key={megaMenuIsOpen} setOpen={(value) => setMegaMenuIsOpen(value)} open={megaMenuIsOpen} >
                    <MegaMenu />
                </CustomModal>
            )}
        </>
    )
}

export default TopMenu