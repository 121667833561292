import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  TextField,
  Typography,
  Paper,
  Card,
} from "@mui/material";
import Done from "view/cms/components/shared/Done";
import Error from "view/cms/components/shared/Error";
import { UserHttpService } from "services/user";

const RoleForm = ({ id, ...props }) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [editingItem, setEditingItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);

  const groupedPermissions = {
    Course: ["list_course", "add_course", "edit_course", "delete_course"],
    Article: ["list_article", "add_article", "edit_article", "delete_article"],
    Notation: ["list_notation", "add_notation", "edit_notation", "delete_notation"],
    Podcast: ["list_podcast", "add_podcast", "edit_podcast", "delete_podcast"],
    Setting: ["setting"],
  };

  const formatPermissionName = (permission) =>
    permission
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());

  useEffect(() => {
    if (id) {
      setLoading(true);
      UserHttpService.loadRole(id)
        .then((item) => {
          setEditingItem(item);
          onEditing(item);
          setLoading(false);
        })
        .catch(() => {
          setError("Failed to load role.");
          setLoading(false);
        });
    }
  }, [id]);

  const onEditing = (item) => {
    for (const key in item) {
      if (key === "permissions") {
        setValue("permissions", item[key]);
      } else {
        setValue(key, item[key]);
      }
    }
  };

  const saveRole = (data) => {
    setError(false);
    const payload = { ...data, ...(id && { id }) };
    setDone(false);
    setLoading(true);

    UserHttpService.saveRole(payload)
      .then(() => {
        setLoading(false);
        setDone(true);
        setError(false);
        if (props.onDone) props.onDone();
      })
      .catch(() => {
        setLoading(false);
        setError("Failed to save role.");
      });
  };

  const togglePermission = (permission) => {
    const currentPermissions = watch("permissions") || [];
    const updatedPermissions = currentPermissions.includes(permission)
      ? currentPermissions.filter((p) => p !== permission)
      : [...currentPermissions, permission];
    setValue("permissions", updatedPermissions);
  };

  return (
    <div className="position-relative">
      {/* <Typography variant="h6">{id ? "Edit Role" : "Create New Role"}</Typography> */}
      {loading && <LinearProgress style={{ width: "100%" }} />}
      <form onSubmit={handleSubmit(saveRole)}>
        <div style={{ marginBottom: "20px" }}>
          <TextField
            fullWidth
            label="Role Name"
            variant="outlined"
            {...register("name", { required: "Role name is required." })}
            error={!!errors.name}
            InputLabelProps={{ shrink: true }}
            helperText={errors.name?.message}
          />
        </div>
        {/* <div style={{ marginBottom: "20px" }}>
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            multiline
            InputLabelProps={{ shrink: true }}
            rows={2}
            {...register("description")}
          />
        </div> */}
        <Typography variant="subtitle1" gutterBottom>
          Permissions:
        </Typography>
        <div className="row">
          {Object.keys(groupedPermissions).map((group) => (
            <div
              className="col-md-6 "
              key={group}
            >
              <div className="permission-card">
                <Typography variant="subtitle2" gutterBottom>
                  {group}
                </Typography>
                <FormGroup>
                  {groupedPermissions[group].map((permission) => (
                    <FormControlLabel
                      key={permission}
                      control={
                        <Checkbox
                          checked={(watch("permissions") || []).includes(permission)}
                          onChange={() => togglePermission(permission)}
                        />
                      }
                      label={formatPermissionName(permission)}
                    />
                  ))}
                </FormGroup>
              </div>

            </div>
          ))}
        </div>
        <div style={{ marginTop: "20px" }}>
          {error && <Error message={error} />}
          {done && <Done />}
          {loading && <LinearProgress style={{ width: "100%" }} />}
          <Button variant="contained" color="primary" type="submit">
            {id ? "Update Role" : "Save Role"}
          </Button>
        </div>
      </form>

    </div>
  );
};

export default RoleForm;
