import { createContext, useEffect, useState } from "react";
import { BaseHttpService } from "../services/base";
import { Utils } from "../utils";
import Constants from "../utils/constants";

export const LanguageContext = createContext();

export const LanguageContextProvider = (props) => {

  const [language, setLanguage] = useState(Utils.getCurrentLanguageName());
  const [refresh, setRefresh] = useState(0);
  const [translations, setTranslations] = useState(null);

  let lan = Utils.getUrlParameter("lan");
  var currentLanguage = Utils.getCurrentLanguageName();


  


 
  const changeLanguage = (language) => {


    if(language === "so")
    {
      document.getElementsByTagName("html")[0].classList.add(Constants.LANGUAGEClassName);
    }
    else{
      document.getElementsByTagName("html")[0].classList.remove(Constants.LANGUAGEClassName);

    }
    Utils.setCurrentLanguageName(language);

    setLanguage(language);
    setRefresh(refresh+1)
    window.location.reload();

  }


  useEffect(() => {
    BaseHttpService.loadTranslations().then((response) => {
      if(response.results){
        setTranslations(response.results)
      }
    })

    currentLanguage = Utils.getCurrentLanguageName();

    if (lan) {
      setLanguage(lan)
      setRefresh(refresh+1)
      currentLanguage= lan
      Utils.setCurrentLanguageName(lan);
    }

    if (['so'].includes(currentLanguage)){
      document.getElementsByTagName("html")[0].classList.add(Constants.LANGUAGEClassName);
      
    } else {
      document.getElementsByTagName("html")[0].classList.remove(Constants.LANGUAGEClassName);
    }
  


  }, [lan]);

  return (
    <LanguageContext.Provider value={{ changeLanguage, language, translations }}>
      {props.children}
    </LanguageContext.Provider>
  );
};
