import React, { useEffect, useState } from 'react'
import { Utils } from 'utils'
import { useQuery } from 'react-query';
import { SettingsHttpService } from 'view/cms/features/feature_settings/services';
import PageLayout from 'view/components/shared/PageLayout'
import TranslateController from 'controller/shared/TranslateController';
import FaqList from 'features/feature_faq/components/main/FaqList';
import FaqsList from 'view/features/feature_home/components/FaqsList';
import { HttpService } from 'services/http';
import HomeTutorials from 'view/features/feature_home/components/HomeTutorials';

function AboutPage() {

	const [settings, setData] = useState(false);
	const [aboutItems, setAboutItems] = useState(false);

	const { isLoading, error: queryError, data, refetch } = useQuery('settings', () => { return SettingsHttpService.loadSettings(1) }, {
		onSuccess: (data) => {
			setData(data)
		},
		// cacheTime: 60*60*24*30*1000// Cache results for 30 days
		refetchInterval: HttpService.DefaultRefetchInterval,
	});

	const { data: AboutItemData } = useQuery('about_items', () => { return SettingsHttpService.loadAboutItems() }, {
		onSuccess: (data) => {
			setAboutItems(data?.results)
		},
		// cacheTime: 60*60*24*30*1000// Cache results for 30 days
		refetchInterval: HttpService.DefaultRefetchInterval,
	});

	const { _t } = TranslateController()


	useEffect(() => {
		Utils.initiateTheme()
	}, [])

	return (
		<PageLayout showbreadcrumbs={false} HeaderChild={
			<h1 className="text-color-light my-3 font-weight-bold text-10 text-sm-8 lan-kfont">{_t("About")}</h1>
		}>

			<div className="row mb-lg-4 pt-md-2 pt-3 pb-4 mt-md-3 lan-rtl lan-kfont">
				<div className="col-lg-12 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">
					<div className="feature-box feature-box-style-2 about-description">
						{/* <div className="feature-box-icon hide-on-small">
							<i className="icons icon-info text-color-primary"></i>
						</div> */}
						<div className="px-2">
							<h2 className="kfont mb-2">{Utils.getDefaultName(settings)}</h2>
							<div className="pl-25 sm-p-0 pull-right  width-50p">
								{/* <img alt={_t("Kurdish Academy")} src={settings ? settings?.image : "/assets/images/logo.png"} className=" bottom-4 " style={{ width: "100%" }} /> */}
								<video src={`/assets/video/tutorial/p1.mp4`} poster={`/assets/video/tutorial/p1.jpg`} controls className='full-width' />

							</div>
							{Utils.parseDescription(settings)}
						</div>


					</div>
				</div>
				{/* <div className="col-lg-6 appear-animation" data-appear-animation="fadeInUpShorter">
					
				</div> */}
			</div>



			<div className="row mb-lg-4  pb-4 pt-4 mt-lg-5 lan-rtl " data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="400">
				{aboutItems && aboutItems.map((item, index) => (
					<div key={item.id + "ai"} className="col-lg-4" >
						<div className="feature-box feature-box-style-2 lan-rtl ">
							<div className="feature-box-icon">
								{index % 3 == 0 && (
									<i className="icons icon-support text-color-primary"></i>
								)}
								{index % 3 == 1 && (
									<i className="icons icon-layers text-color-primary"></i>
								)}
								{index % 3 == 2 && (
									<i className="icons icon-menu text-color-primary"></i>
								)}
							</div>
							<div className="feature-box-info kfont lan-text-right">
								<h4 className="font-weight-bold mb-2 kfont lan-text-right">{Utils.getDefaultName(item)}</h4>
								{Utils.parseDescription(item)}
							</div>
						</div>
					</div>
				))}

			</div>

			<div className="bg-color-dark mt-lg-5 p-relative z-index-1 mb-lg-4  bg-position-center bg-size-cover lazyload" style={{ backgroundImage: "url(assets/images/background-1.jpg)" }}>
				<div className="container ">
					<div className="row align-items-center py-5 ">
						<div className="col-lg-10 mx-auto">
							{/* <h2 className='lan-kfont text-center text-color-light mb-4'>{_t("FAQ")}</h2> */}
							<FaqsList />

							<div className="mt-5">
								<HomeTutorials padding={false} />
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <section className="section  border-0 m-0">
				<div className="container">
					<div className="row my-5">
						<div className="col-lg-6 pr-5">
							<h2 className=" font-weight-normal text-6 mb-2 pb-1"><strong className="font-weight-extra-bold">Who</strong> We Are</h2>
							<p className="lead opacity-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus blandit massa enikklam id valorem ipsum dolor sit amet, consectetur adipiscing.</p>
							<p className="opacity-6">Phasellus blandit massa enim. Nullam id varius elit. blandit massa enim d varius blandit massa enimariusi d varius elit.</p>
							<a href="#" className="font-weight-semibold text-decoration-none learn-more text-2">VIEW MORE <i className="fas fa-chevron-right ml-2"></i></a>
						</div>
						<div className="col-lg-6">
							<div className="progress-bars mt-5">
								<div className="progress-label">
									<span>HTML/CSS</span>
								</div>
								<div className="progress progress-dark mb-2">
									<div className="progress-bar progress-bar-primary" data-appear-progress-animation="100%">
										<span className="progress-bar-tooltip">100%</span>
									</div>
								</div>
								<div className="progress-label">
									<span>Design</span>
								</div>
								<div className="progress progress-dark mb-2">
									<div className="progress-bar progress-bar-primary" data-appear-progress-animation="85%" data-appear-animation-delay="300">
										<span className="progress-bar-tooltip">85%</span>
									</div>
								</div>
								<div className="progress-label">
									<span>WordPress</span>
								</div>
								<div className="progress progress-dark mb-2">
									<div className="progress-bar progress-bar-primary" data-appear-progress-animation="75%" data-appear-animation-delay="600">
										<span className="progress-bar-tooltip">75%</span>
									</div>
								</div>
								<div className="progress-label">
									<span>Photoshop</span>
								</div>
								<div className="progress progress-dark mb-2">
									<div className="progress-bar progress-bar-primary" data-appear-progress-animation="85%" data-appear-animation-delay="900">
										<span className="progress-bar-tooltip">85%</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			{/* <div className="container appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300">
				<div className="row py-5 my-5">
					<div className="col-md-6 order-2 order-md-1 text-center text-md-left">
						<div className="owl-carousel owl-theme nav-style-1 nav-center-images-only stage-margin mb-0" data-plugin-options="{'responsive': {'576': {'items': 1}, '768': {'items': 1}, '992': {'items': 2}, '1200': {'items': 2}}, 'margin': 25, 'loop': false, 'nav': true, 'dots': false, 'stagePadding': 40}">
							<div>
								<img className="img-fluid rounded-0 mb-4" src="assets/images/sample.jpg" alt="" />
								<h3 className="font-weight-bold text-color-dark text-4 mb-0">John Doe</h3>
								<p className="text-2 mb-0">CEO</p>
							</div>
							<div>
								<img className="img-fluid rounded-0 mb-4" src="assets/images/sample.jpg" alt="" />
								<h3 className="font-weight-bold text-color-dark text-4 mb-0">Jessica Doe</h3>
								<p className="text-2 mb-0">CEO</p>
							</div>
							<div>
								<img className="img-fluid rounded-0 mb-4" src="assets/images/sample.jpg" alt="" />
								<h3 className="font-weight-bold text-color-dark text-4 mb-0">Chris Doe</h3>
								<p className="text-2 mb-0">DEVELOPER</p>
							</div>
							<div>
								<img className="img-fluid rounded-0 mb-4" src="assets/images/sample.jpg" alt="" />
								<h3 className="font-weight-bold text-color-dark text-4 mb-0">Julie Doe</h3>
								<p className="text-2 mb-0">SEO ANALYST</p>
							</div>
						</div>
					</div>
					<div className="col-md-6 order-1 order-md-2 text-center text-md-left mb-5 mb-md-0">
						<h2 className="text-color-dark font-weight-normal text-6 mb-2 pb-1">Meet <strong className="font-weight-extra-bold">Our Team</strong></h2>
						<p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit massa enim. Nullam id varius nunc.</p>
						<p className="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus blandit massa enim. Nullam id varius nunc. Vivamus bibendum magna ex, et faucibus lacus venenatis eget.</p>
						<a href="page-team.html" className="btn btn-dark font-weight-semibold rounded-0 px-5 btn-py-2 text-2">LEARN MORE</a>
					</div>
				</div>
			</div> */}
		</PageLayout>


	)
}

export default AboutPage