import React, { useState } from 'react'
import ArrowTops from './home/ArrowTops'
import { SettingsHttpService } from 'view/cms/features/feature_settings/services';
import { useQuery } from 'react-query';
import { PostHttpService } from 'view/cms/features/feature_blog/services';
import { Utils } from 'utils';
import { Link } from 'react-router-dom';
import TranslateController from 'controller/shared/TranslateController';
import { CourseHttpService } from 'services/course';

function Footer() {

	const [settings, setData] = useState(false);
	const [courses, setCourses] = useState(false);

	const { isLoading, error: queryError, data, refetch } = useQuery('settings', () => { return SettingsHttpService.loadSettings(1) }, {
		onSuccess: (data) => {
			setData(data)
		},
		// cacheTime: 60*60*24*30*1000// Cache results for 30 days
		refetchInterval: 60 * 100000,
	});




	const [categories, setCategories] = useState(null);

	const { data: categoriesData, refetch: refetchCategories } = useQuery('categories', () => { return PostHttpService.loadCategories(null, 0) }, {
		onSuccess: (data) => {
			setCategories(Utils.BuildCategories(data))
		},
		refetchInterval: 60 * 100000,
	});


	// const { data: coursesData } = useQuery('courses', () => { return CourseHttpService.loadCoursesBySize(3) }, {
	// 	onSuccess: (data) => {
	// 		setCourses(data.results)
	// 	},
	// 	refetchInterval: 600 * 100000,
	// });


	const { _t } = TranslateController()



	return (
		<footer id="footer" className="p-relative bg-dark border-top-0">
			<div className="container pt-5 pb-3">
				<div className="row pt-5 ">
					<div className="col-lg-6 mt-5 pt-3 order-md-1 order-2 text-small-center">
						<a href="demo-creative-agency-2.html" className="text-decoration-none">
							<img src="/assets/images/logo.png" width="123" height="32" className="img-fluid mb-3" alt="Porto" />
						</a>
						{/* <ul className="list list-unstyled">
							<li className="d-flex align-items-center mb-4">
								<a href="/cdn-cgi/l/email-protection#ec9c839e9883ac8f9e898d98859a89c18d8b89828f95c1dec28f8381" className="d-inline-flex align-items-center text-decoration-none text-color-light text-color-hover-primary font-weight-semibold text-4-5"><span className="__cf_email__" data-cfemail="b1c1dec3c5def1d5dedcd0d8df9fd2dedc">[email&#160;protected]</span></a>
							</li>

						</ul> */}
						<ul className="social-icons social-icons-clean social-icons-medium social-icons-lg">
							<li className="social-icons-facebook">
								<a href={settings ? settings.facebook : ""} target="_blank" title="Facebook">
									<i className="fab fa-facebook-f text-color-light"></i>
								</a>
							</li>
							{(settings && settings.twitter) && (
								<li className="social-icons-twitter">
									<a href={settings ? settings.twitter : ""} target="_blank" title="Twitter">
										<i className="fab fa-twitter text-color-light"></i>
									</a>
								</li>
							)}
							<li className="social-icons-instagram">
								<a href={settings ? settings.instagram : ""} target="_blank" title="Instagram">
									<i className="fab fa-instagram text-color-light"></i>
								</a>
							</li>
							{(settings && settings.spotify) && (
								<li className="social-icons-linkedin">
									<a href={settings ? settings.spotify : ""} target="_blank" title="Spotify">
										<i className="fab fa-spotify text-color-light"></i>
									</a>
								</li>
							)}
						</ul>
					</div>
					<div className="col-lg-6 mt-lg-5 pt-lg-3 order-md-2 order-1 pl-sm-4 lan-pr-4">
						<div className="row mb-5-5">
							<div className="col-md-3 mb-4 mb-lg-0 lan-text-right">
								<h4 className="text-color-light font-weight-bold mb-3">{_t("Kurdish Academy")}</h4>
								<ul className="list list-unstyled text-3-5">
									{/* <li><a href="/dictionary" className="text-color-grey text-color-hover-primary lan-kfont">{_t("Dictionary")}</a></li> */}
									<li className='mb-0'>
										{!Utils.isRtl() && (<i className="fa fa-chevron-right chevron-small mr-2"></i>)}
										<a href="/about" className="text-color-grey text-color-hover-primary lan-kfont ">{_t("About")}
											{Utils.isRtl() && (<i className="fa fa-chevron-left chevron-small ml-2"></i>)}  </a></li>
									<li className='mb-0'>
										{!Utils.isRtl() && (<i className="fa fa-chevron-right chevron-small mr-2"></i>)}
										<a href="/faq" className="text-color-grey text-color-hover-primary lan-kfont ">{_t("FAQ")}
											{Utils.isRtl() && (<i className="fa fa-chevron-left chevron-small ml-2"></i>)}
										</a></li>
								</ul>
							</div>
							<div className="col-md-3 mb-4 mb-lg-0 lan-text-right">
								<h4 className="text-color-light font-weight-bold mb-3">{_t("Academy")}</h4>
								<ul className="list list-unstyled text-3-5">
									{courses && courses.map(course => (
										<li className='mb-0'><a className="text-color-grey text-color-hover-primary lan-kfont" key={"footer_course_" + course.id} href={`/course/${course.id}`}>{Utils.getDefaultName(course)}</a></li>
									))}
									<li className='mb-0'>
										<a className="text-color-grey text-color-hover-primary lan-kfont" href={`/course/`}>
											{!Utils.isRtl() && (<i className="fa fa-chevron-right chevron-small mr-2"></i>)}
											{_t("Courses")}
											{Utils.isRtl() && (<i className="fa fa-chevron-left chevron-small ml-2"></i>)}
										</a>
									</li>
									<li className='mb-0'>
										<a className="text-color-grey text-color-hover-primary lan-kfont" href={`/notations/`}>
											{!Utils.isRtl() && (<i className="fa fa-chevron-right chevron-small mr-2"></i>)}
											{_t("Notations")}
											{Utils.isRtl() && (<i className="fa fa-chevron-left chevron-small ml-2"></i>)}
										</a>
									</li>
									<li className='mb-0'>
										<a className="text-color-grey text-color-hover-primary lan-kfont" href={`/articles/`}>
											{!Utils.isRtl() && (<i className="fa fa-chevron-right chevron-small mr-2"></i>)}
											{_t("Articles")}
											{Utils.isRtl() && (<i className="fa fa-chevron-left chevron-small ml-2"></i>)}
										</a>
									</li>
									<li className='mb-0'>
										<a className="text-color-grey text-color-hover-primary lan-kfont" href={`/podcasts/`}>
											{!Utils.isRtl() && (<i className="fa fa-chevron-right chevron-small mr-2"></i>)}
											{_t("Podcasts")}
											{Utils.isRtl() && (<i className="fa fa-chevron-left chevron-small ml-2"></i>)}
										</a>
									</li>
									
									{/* <li className='mb-0'><a className="text-color-grey text-color-hover-primary lan-kfont" href={`/Instructors/`}>
										{!Utils.isRtl() && (<i className="fa fa-chevron-right chevron-small  mr-2"></i>)}
										{_t("Instructors")}
										{Utils.isRtl() && (<i className="fa fa-chevron-left chevron-small  ml-2"></i>)}

									</a></li> */}
									<li className='mb-0'><a className="text-color-grey text-color-hover-primary lan-kfont" href={`/dictionary/`}>
										{!Utils.isRtl() && (<i className="fa fa-chevron-right chevron-small  mr-2"></i>)}
										{_t("Dictionary")}
										{Utils.isRtl() && (<i className="fa fa-chevron-left chevron-small  ml-2"></i>)}

									</a></li>

								</ul>
							</div>
							<div className="col-md-3 mb-4 mb-lg-0 lan-text-right">
								<h4 className="text-color-light font-weight-bold mb-3">{_t("Our team")}</h4>
								<ul className="list list-unstyled text-3-5">
									{/* <li className='mb-0'>
										<a className="text-color-grey text-color-hover-primary lan-kfont" href={`/authors/`}>
											{!Utils.isRtl() && (<i className="fa fa-chevron-right chevron-small mr-2"></i>)}
											{_t("Authors")}
											{Utils.isRtl() && (<i className="fa fa-chevron-left chevron-small ml-2"></i>)}
										</a>
									</li> */}
									<li className='mb-0'>
										<a className="text-color-grey text-color-hover-primary lan-kfont" href={`/transcribers/`}>
											{!Utils.isRtl() && (<i className="fa fa-chevron-right chevron-small mr-2"></i>)}
											{_t("Transcribers")}
											{Utils.isRtl() && (<i className="fa fa-chevron-left chevron-small ml-2"></i>)}
										</a>
									</li>
									<li className='mb-0'>
										<a className="text-color-grey text-color-hover-primary lan-kfont" href={`/instructors/`}>
											{!Utils.isRtl() && (<i className="fa fa-chevron-right chevron-small mr-2"></i>)}
											{_t("Instructors")}
											{Utils.isRtl() && (<i className="fa fa-chevron-left chevron-small ml-2"></i>)}
										</a>
									</li>
									<li className='mb-0'>
										<a className="text-color-grey text-color-hover-primary lan-kfont" href={`/narrators/`}>
											{!Utils.isRtl() && (<i className="fa fa-chevron-right chevron-small mr-2"></i>)}
											{_t("Podcast Narrator")}
											{Utils.isRtl() && (<i className="fa fa-chevron-left chevron-small ml-2"></i>)}
										</a>
									</li>
									<li className='mb-0'>
										<a className="text-color-grey text-color-hover-primary lan-kfont" href={`/podcast-writers/`}>
											{!Utils.isRtl() && (<i className="fa fa-chevron-right chevron-small mr-2"></i>)}
											{_t("Podcast Writers")}
											{Utils.isRtl() && (<i className="fa fa-chevron-left chevron-small ml-2"></i>)}
										</a>
									</li>
									

								</ul>
							</div>
							<div className="col-md-3 mb-4 mb-lg-0 lan-text-right">
								<h4 className="text-color-light font-weight-bold mb-3">{_t("Language")}</h4>
								<ul className="list list-unstyled text-3-5">
									<li className='mb-0'><a href="/?lan=en" className="text-color-grey text-color-hover-primary lan-kfont">
										{!Utils.isRtl() && (<i className="fa fa-chevron-right chevron-small  mr-2"></i>)}
										English
										{Utils.isRtl() && (<i className="fa fa-chevron-left chevron-small   ml-2 "></i>)}
									</a></li>
									<li className='mb-0'><a href="/?lan=so" className="text-color-grey text-color-hover-primary kfont">
										{!Utils.isRtl() && (<i className="fa fa-chevron-right chevron-small  mr-2"></i>)}
										کوردی
										{Utils.isRtl() && (<i className="fa fa-chevron-left chevron-small   ml-2"></i>)}
									</a></li>
									<li className='mb-0'><a href="/?lan=ku" className="text-color-grey text-color-hover-primary lan-kfont">
										{!Utils.isRtl() && (<i className="fa fa-chevron-right chevron-small  mr-2"></i>)}
										Kurdi
										{Utils.isRtl() && (<i className="fa fa-chevron-left chevron-small   ml-2"></i>)}
									</a></li>
								</ul>
							</div>
						</div>
						{/* <div className="row">
							<div className="col lan-rtl lan-text-right">
								<h4 className="text-color-light font-weight-bold mb-3 ">{_t("Articles")}</h4>
								{categories && categories.map(category => (
									<Link key={"footer_category_" + category.id} to={`/articles/${category.id}`}><span className="badge bg-dark--100 font-weight-normal text-2 badge-md anim-hover-translate-top-5px transition-2ms me-2 mb-2 lan-kfont">{Utils.getDefaultName(category)}</span></Link>
								))}
							</div>
						</div> */}
					</div>
				</div>
			</div>
			<div className="footer-copyright bg-transparent">
				<div className="container pb-5">
					<hr className="bg-color-light opacity-1" />
					<div className="row">
						<div className="col-lg-6 ">
						</div>
						<div className="col-lg-6 text-lg-end mt-4 text-small-center">
							<p className="text-start text-right text-2-5 text-small-center text-light mb-0">Kurdish Academy © 2024. All Rights Reserved.</p>
							<a href="#" className="text-color-grey text-color-hover-primary">Privacy Policy</a>
							<a href="#" className="text-color-grey text-color-hover-primary ms-3">Terms of Use</a>
						</div>
					</div>
				</div>
			</div>
			{/* <ArrowTops /> */}
		</footer>
	)
}

export default Footer