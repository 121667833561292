import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import PageLayout from 'view/components/shared/PageLayout'
import BlogStyle1 from '../components/blog_styles/BlogStyle1'
import BlogStyle2 from '../components/blog_styles/BlogStyle2'
import BlogStyle3 from '../components/blog_styles/BlogStyle3'
import PostsAside from '../components/PostsAside'
import { LanguageContext } from 'contexts/LanguageContext'
import { Utils, translate } from 'utils'
import { useQuery } from 'react-query'
import { PostHttpService } from 'view/cms/features/feature_blog/services'
import TranslateController from 'controller/shared/TranslateController'

function Publications() {

    var { category_id } = useParams();

    if (!category_id) category_id = 20;




    const [query, setQuery] = useState()
    const [posts, setPosts] = useState(null);


    const { isLoading, error, data, refetch } = useQuery(['posts', category_id], () => { return PostHttpService.loadPosts(category_id, query) }, {
        onSuccess: (data) => {
            setPosts(data.results)
        },
        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
        refetchInterval: 600 * 10000,
    });

    useEffect(() => {
        refetch()
    }, [query])






    return (
        <PageLayout showbreadcrumbs={false} HeaderChild={<HeaderChild category_id={category_id} query={query} setSearchQuery={setQuery} />}>
            <section class="section border-0 m-0 pb-3">
                <div class="container container-xl-custom">
                    <div class="row pb-1">
                        {posts && posts.map((post, index) => (
                            <div key={`blog_post_${post.id}`} class="col-4 col-lg-2 mb-4 pb-2">
                                <Link to={`/post/${post.id}`}>
                                    <article>
                                        <div class="thumb-info thumb-info-no-borders thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
                                            <div class="thumb-info-wrapper thumb-info-wrapper-opacity-6">
                                                <img src={post.image} class="img-fluid book-list-image" alt={post.title} />
                                                <div class="thumb-info-title bg-transparent p-4 lan-text-right">
                                                    <div class="thumb-info-inner mt-1">
                                                        <h2 class="text-color-light line-height-2 text-4 font-weight-bold mb-0 lan-rtl lan-text-right">{post.title}</h2>
                                                    </div>
                                                    <div class="thumb-info-show-more-content">
                                                        <p class="mb-0  line-height-normal text-size-12 mb-1 mt-2 text-light opacity-5 lan-kfont lan-text-right line-height-normal">{Utils.parse(post.short_description,15)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </Link>
                            </div>
                        )
                        )}
                    </div>
                </div>
            </section>

        </PageLayout>
    )
}



function HeaderChild({ category_id, setSearchQuery }) {
    const {_t} = TranslateController();

    const [category, setCategory] = useState(null)

    useEffect(() => {
        _getCategory(category_id)
    }, [category_id])


    const _getCategory = (category_id) => {
        PostHttpService.loadCategory(category_id).then((category) => {
            setCategory(category)
        });
    }

    const [query, setQuery] = useState("")


    return (
        <>

            <h1 className="text-color-light pt-3 pb-3 font-weight-bold text-10">{_t("Publications")}</h1>
            <form action="" onSubmit={(e) => { e.preventDefault(); setSearchQuery(query) }}>
                <div className="search-container">
                    <div className="simple-search input-group mt-3 mb-4">
                        <input className="form-control text-1 text-light" value={query} name="q" onChange={(e) => { setQuery(e.target.value) }} type="search" placeholder="Search..." />
                        <button className="btn" type="submit" aria-label="Search">
                            <i className="fas fa-search header-nav-top-icon"></i>
                        </button>
                    </div>
                </div>
            </form>
        </>

    )
}

export default Publications