import React, { useContext } from 'react';
import { UserContext } from 'contexts/UserContext';
import NoPermission from 'view/cms/components/shared/NoPermission';

const PermissionCheck = ({ permission, showError = false, children }) => {
  const { hasPermission , userLoading } = useContext(UserContext);

  // Check if user has the required permission
  if (hasPermission(permission)) {
    return children; // Render the children if permission exists
  } else if(showError && !userLoading) {
    return <NoPermission />; // Otherwise, render NoPermission component
  } else {
  return <></>; // Otherwise, render NoPermission component
}
};

export default PermissionCheck;
