import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import CustomModal from 'view/components/modals/CustomModal';
import { UserHttpService } from 'services/user';
import { Utils } from 'utils';
import DeleteDialog from 'view/cms/components/shared/DeleteDialog';
import RoleForm from './RoleForm';
import { LinearProgress } from '@mui/material';

function RolesList({ }) {
  const [data, setData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
    _getData(newPageNumber)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  };

  useEffect(() => {

    _getData(1)

    return () => {
    }
  }, [])

  const _getData = (pageNumber) => {

    setLoading(true);

    UserHttpService.loadRoles(pageNumber).then((items) => {
      if (items.results) {
        setData(items.results)
        setCount(items.count)
        setTotalPages(Math.ceil(items.count / 30));
      }
      setLoading(false);

    });

  }

  const handleDelete = (e, id) => {
    setSelectedId(id);
    setRefresh(refresh + 1);
    setDeleteDialogIsOpen(true);
  };


  const handleEdit = (e, support_message) => {
    e.preventDefault();
    setSelectedId(support_message);
    setRefresh(refresh + 1);
    setEditModalIsOpen(true);
  };


  return (
    <div className='box'>
      <div className="box-header mb-5">
        <h5></h5>
        <div className="btns box-header-btns d-flex align-items-center">
          Count : {count}
          {/* <SearchQuery onQuery={(query) => setSearchQuery(query)} query={searchQuery} /> */}
          <button className="btn btn-icon bg-success" onClick={() => { setEditModalIsOpen(!editModalIsOpen) }}><i className="fa fa-plus "></i></button>
        </div>
      </div>
      <div className="box-body general-list">
        {loading && <LinearProgress style={{ width:"100%" }} />}
        <table className="table table-striped">
          <thead>
            <tr>
              <th className='hide-on-small'>Name </th>
              <th className='hide-on-small'>Date </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data && data.map(item => (
              <tr key={"people_item_" + item.id}>
                <td className='hide-on-small kfont' onClick={(e)=>{ handleEdit(e,item.id) }} ><a href="#Edit">{item.name}</a> </td>
                <td className='hide-on-small'>{Utils.getDate(item.created_at)} </td>
                <td className='text-right'>
                  <a href="#" onClick={(e) => { handleEdit(e, item.id) }} className="btn btn-outline btn-swap-1">
                    <span><i className="fas fa-edit color-gray "></i></span>
                    <span>Edit <i className="fas fa-edit ms-2 color-gray "></i></span>
                  </a>
                  <a href="#" onClick={(e) => { handleDelete(e, item.id) }} className="btn btn-outline btn-swap-1">
                    <span><i className="fas fa-trash color-gray "></i></span>
                    <span>Delete <i className="fas fa-trash ms-2 color-gray "></i></span>
                  </a>

                </td>
              </tr>
            ))}
          </tbody>

        </table>
        <ul className="pagination">
          <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(pageNumber - 1)} disabled={pageNumber === 1}>
              <i className="fas fa-angle-left"></i>
            </button>
          </li>
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
          <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(pageNumber + 1)} disabled={pageNumber === totalPages}>
              <i className="fas fa-angle-right"></i>
            </button>
          </li>
        </ul>
      </div>


      <DeleteDialog
        url={`/auth/roles/${selectedId}/`}
        isOpen={deleteDialogIsOpen}
        key={"mdl" + deleteDialogIsOpen + selectedId}
        setOpen={setDeleteDialogIsOpen}
        notifyDone={() => { _getData(pageNumber); setDeleteDialogIsOpen(false) }}
      />

      {editModalIsOpen && (
        <CustomModal big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
          open={editModalIsOpen}  >

          <RoleForm id={selectedId} onDone={_getData} />


        </CustomModal>
      )}
    </div>
  )
}

export default RolesList