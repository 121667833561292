import React, { useState } from 'react'
import CMSLayout from 'view/cms/components/shared/CMSLayout'
import PostsList from '../components/PostsList'
import PermissionCheck from 'view/cms/components/shared/PermissionCheck'

function PostsPage() {



  return (
    <CMSLayout>
      <PermissionCheck permission="list_article" showError={1}>
        <PostsList />
      </PermissionCheck>
    </CMSLayout>
  )
}

export default PostsPage