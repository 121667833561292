import { HttpService } from "services/http";


export class UserHttpService {

    static async loadUsers(role,pageNumber=1,searchQuery=null) {
        var query = "?size=50"
        query += `&page=${pageNumber}`

        if(role){
            query += "&role="+role 
        }

        if(searchQuery){
            query += "&query="+searchQuery 
        }

        
        var service = new HttpService();
        var response = await service.get(`/auth/user/${query}`,null);
        return response;
    }

    static async loadOnlineUsers(pageNumber=1,size=10) {
        var query = `?size=${size}`
        query += `&page=${pageNumber}&sort=online`

        var service = new HttpService();
        var response = await service.get(`/auth/user/${query}`,null);
        return response;
    }

}

