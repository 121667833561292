import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Avatar, Button, Checkbox, TextField, TextareaAutosize } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Edit } from "@mui/icons-material";
import Done from "view/cms/components/shared/Done";
import Error from "view/cms/components/shared/Error";
import LoadingInside from "view/cms/components/shared/LoadingInside";
import { SettingsHttpService } from "../../feature_settings/services";
import SelectGender from "./SelectGender";
import SelectRole from "./SelectRole";
import SelectCountry from "./SelectCountry";
import SelectCity from "./SelectCity";
import { BaseHttpService } from "services/base";
import { Utils } from "utils";
import SelectPermission from "./SelectPermission";

function UserForm({ id, lesson, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [item_image, setImage] = useState(null);
  const [data, setData] = useState(null);


  const fileRef = useRef()

  useEffect(() => {
    if (id) {
      BaseHttpService.loadUser(id).then(item => {
        setData(item)
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)


    for (const key in item) {
      setValue(key, item[key])
    }

    setRefresh(refresh + 1)
    setImage(item.image)
  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    var body = {
      "id": id ? id : data.id,
      "email": data.email,
      "first_name": data.first_name,
      "last_name": data.last_name,
      "gender": data.gender,
      "country_id": data.country_id,
      "city_id": data.city_id,
      "livingcountry_id": data.livingcountry_id,
      "livingcity_id": data.livingcity_id,
      "role": data.role,
      "permission_id": data.permission,
    }


    if (data?.password.length > 5) {
      body["password"] = data.password
    }


    setDone(false)
    setLoading(true)
    BaseHttpService.saveUser(body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };

  const handleValue = (value, name) => {
    switch (name) {

      case "gender":
        setValue("gender", value)
        break;
      case "country_id":
        setValue("country_id", value)
        break;
      case "city_id":
        setValue("city_id", value)
        break;
      case "livingcountry_id":
        setValue("livingcountry_id", value)
        break;
      case "livingcity_id":
        setValue("livingcity_id", value)
        break;

      case "role":
        setValue("role", value)
        break;
      
      case "permission":
        setValue("permission", value)
        break;
      default:
        break;
    }
  }

  const isVerifiedLabel = { inputProps: { 'aria-label': 'Is Verified' } };
  const isActiveLabel = { inputProps: { 'aria-label': 'Is Active' } };


  return (
    <div>


      <form className="mt-4" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <TextField
                fullWidth
                label="First Name"
                className="kfont"
                value={watch('first_name') ?? ""}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("first_name")}
              />
            </div>
            <div className="form-group">
              <TextField
                fullWidth
                className="kfont"

                label="Last Name"
                value={watch('last_name') ?? ""}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("last_name")}
              />
            </div>
            <div className="form-group">
              <TextField
                fullWidth
                label="Email"
                value={watch('email') ?? ""}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("email")}
              />
            </div>

            <div className="form-group">
              <TextField
                fullWidth
                label="Password"
                value={watch('password') ?? ""}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("password")}
              />
            </div>

            <div className="form-group">
              Registered: {Utils.getShortDate(watch("created_at"))}
            </div>

            <hr className="solid" />
          </div>
          

          <div className="col-md-4">

            <div className="form-group ">
              <SelectCountry width={250} placeholder={'Birth Country'} onValue={handleValue} value={watch("country_id")} name="country_id" />
            </div>
            <div className="form-group ">
              <SelectCity width={250} placeholder={'Birth City'} key={watch("country_id")} countryId={watch("country_id")} name="city_id" value={watch("city_id")} onValue={handleValue} />
            </div>
            <div className="form-group ">
              <SelectCountry width={250} placeholder={'Living Country'} onValue={handleValue} value={watch("livingcountry_id")} name="livingcountry_id" />
            </div>
            <div className="form-group ">
              <SelectCity width={250} placeholder={'Living City'} key={watch("livingcountry_id")} countryId={watch("livingcountry_id")} name="livingcity_id" value={watch("livingcity_id")} onValue={handleValue} />
            </div>
            <div className="form-group">
              <SelectGender width={250} callback={handleValue} value={watch("gender")} border={"1"} />
            </div>
            <div className="form-group">
              <SelectRole width={250} callback={handleValue} value={watch("role")} border={"1"} />
            </div>

            <div className="form-group">
              <SelectPermission width={250} callback={handleValue} value={watch("permission")} border={"1"} />
            </div>

          </div>

          <div className="col-md-4">
            <div className="form-group ">
              <a href={Utils.getUserImage(data)} target="_blank"><Avatar alt={data?.first_name} src={Utils.getUserImage(data)} sx={{ height: '100px', width: '100px' }} /></a>
            </div>
            <div className="form-group">
              <Checkbox {...isVerifiedLabel} defaultChecked size="small" /> is Verified
            </div>
            <div className="form-group">
              <Checkbox {...isActiveLabel} defaultChecked size="small" /> is Active
            </div>
          </div>

        </div>
        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <Edit /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add User"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <LoadingInside />}
        </div>
      </form>
    </div>
  );
}

export default UserForm;
