import { HttpService } from "../http";


export class BaseHttpService {
    
    // This function loads profile by id
    static async loadUser(id){
        var service = new HttpService();
        var response = await service.get(`/auth/user/${id}`,null);
        return response;
    }

    static async loadUserDetails(id){
        var service = new HttpService();
        var response = await service.get(`/auth/user/details/${id}`,null);
        return response;
    }

    static async loadMe(){
        var service = new HttpService();
        var response = await service.get(`/auth/profile/me`,null);
        return response;
    }

    static async saveUser(body) {
        
        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/auth/profiles/${body.id}/`, body);
        }
        else
            response = await service.post('/auth/user/new/', body);

        return await response
    }

    static async loadCountries(){
        var service = new HttpService();
        var response = await service.get(`/base/country/?size=1000000`,null);
        return response;
    }

    static async loadCities(country_id){
        var service = new HttpService();
        var response = await service.get(`/base/country/${country_id}/cities?size=1000000`,null);
        return response;
    }

    static async saveLanguage(body) {
        
        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/base/language/${body.id}/`, body);
        }
        else
            response = await service.post('/base/language/', body);

        return await response
    }

    static async loadLanguage(id){
        var service = new HttpService();
        var response = await service.get(`/base/language/${id}`,null);
        return response;
    }

    static async loadLanguages(){
        var service = new HttpService();
        var response = await service.get('/base/language/',null);
        return response;
    }

    static async loadDictionaryLanguages(){
        var service = new HttpService();
        var response = await service.get('/api/dictionarylanguage/',null);
        return response;
    }

    static async loadCity(id){
        var service = new HttpService();
        var response = await service.get(`/base/city/${id}`,null);
        return response;
    }

    static async saveCity(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/base/city/${body.id}`, body);
        }
        else
            response = await service.post('/base/city/', body);

        return await response
    }

    static async deleteItem(url){
        var service = new HttpService();
        var response = await service.delete(`${url}`);
        return response;
    }

    ////////////////////////////////////////////////////////////////

    static async loadGalleries(page,type="",size=15){
        var service = new HttpService();
        var query = `?page=${page}&size=${size}`;
        
        if(type){
            query += `&type=${type}`
        }

        var response = await service.get(`/postapi/gallery/${query}`,null);
        return response;
    }

    static async loadGallery(id){
        var service = new HttpService();
        var response = await service.get(`/postapi/gallery/${id}`,null);
        return response;
    }

    static async SaveGallery(title,type="", file) {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("type", type);

        if(type == "document" || type=='music'){
            formData.append("document", file, file.name);
        }
        else{
            formData.append("image", file, file.name);
        }
        
        var service = new HttpService();
        var response = await service.post(`/postapi/gallery/`,formData,true);
      
        return response;
    }

    static async SaveGalleryForm(body) {
        const formData = new FormData();
        formData.append("title", body.title);
        formData.append("type", body.type);

        if(body.file){
            formData.append("image", body.file, body.file.name);
        }

        var service = new HttpService();
        var response;
        if (body.id) {
            response = await service.put(`/postapi/gallery/${body.id}/`,formData,true);
        }
        else
            response = await service.post('/postapi/gallery/',formData,true);
      
        return response;
    }

    ////////////////////////////////////////////////////////////////


    
    static async loadSettings(){
        var service = new HttpService();
        var response = await service.get('/base/setting/',null);
        return response;
    }

    static async saveTranslation(body) {
        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/api/translate/${body.id}`, body);
        }
        else
            response = await service.post('/api/translate/', body);

        return await response
    }

    static async loadTranslation(id){
        var service = new HttpService();
        var response = await service.get(`/api/translate/${id}`,null);
        return response;
    }

    static async loadTranslations(){
        
        var service = new HttpService();
        var response = await service.get(`/api/translate/?size=10000`,null);
        return response;
    }

    static async searchTranslations(query){
        
        var service = new HttpService();
        var response = await service.get(`/api/translate/?query=${query}`,null);
        return response;
    }

    static async loadDictionaryEntries(start_letter,search_query=""){
        var query= `?size=100`
        if(start_letter){
            query += `&start_letter=${start_letter}`
        }

        if(search_query){
            query += `&query=${search_query}`
        }


        var service = new HttpService();
        var response = await service.get(`/api/dictionaryentry/${query}`,null);
        return response;
    }

    static async searchDictionaryEntries(query=""){
        var service = new HttpService();
        var response = await service.get(`/api/dictionaryentry/?query=${query}`,null);
        return response;
    }

    static async loadDictionaryEntryCharacters(){
        var service = new HttpService();
        var response = await service.get(`/api/dictionaryentrycharacters/`,null);
        return response;
    }
    

    static async loadDictionaryEntry(id){
        var service = new HttpService();
        var response = await service.get(`/api/dictionaryentry/${id}`,null);
        return response;
    }

    static async saveDictionaryEntry(body) {
        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/api/dictionaryentry/${body.id}`, body);
        }
        else
            response = await service.post('/api/dictionaryentry/', body);

        return await response
    }

    
    static async saveSetting(body) {
        const formData = new FormData();

        formData.append("site_title", body.site_title);
        formData.append("site_description", body.site_description);
        formData.append("about_text", body.about_text);
        formData.append("contact_text", body.contact_text);
        formData.append("privacy_text", body.privacy_text);
        formData.append("terms_text", body.terms_text);
        formData.append("cookie_text", body.cookie_text);
        
        if (body.file) formData.append("logo", body.file, body.file.name);

        var service = new HttpService();
        var response;


        if (body.id) {
            response = await service.put(`/base/setting/${body.id}`, formData, true);
        }
        else
            response = await service.post('/base/setting/', formData, true);

        return await response
    }

    static async loadSupportMessages(pageNumber=1,size=50){
        var service = new HttpService();
        var response = await service.get(`/api/supportmessages/?size=${size}&$page=${pageNumber}`,null);
        return response;
    }

    static async loadUserSupportMessages(pageNumber=1,size=50){
        var service = new HttpService();
        var response = await service.get(`/api/support/?size=${size}&$page=${pageNumber}`,null);
        return response;
    }

    static async loadSupportMessage(id){
        var service = new HttpService();
        var response = await service.get(`/api/supportmessages/${id}`,null);
        return response;
    }

    static async saveSupportMessageToCourseParticipants(body,query) {
        
        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/api/support/${body.id}/`, body);
        }
        else
            response = await service.post(`/api/supportmessages/notify?${query}`, body);

        return await response
    }
    static async saveSupportMessage(body) {
        
        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/api/support/${body.id}/`, body);
        }
        else
            response = await service.post('/api/support/', body);

        return await response
    }

    static async saveSupportMessageToUser(body) {
        
        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/api/supportmessages/${body.id}/`, body);
        }
        else
            response = await service.post('/api/supportmessages/', body);

        return await response
    }
    
    static async loadSupportMessageReplies(supportMessageId,pageNumber=1,size=50){
        var service = new HttpService();
        var response = await service.get(`/api/supportmessages/${supportMessageId}/replies`,null);
        return response;
    }

    static async saveSupportMessageReply(supportMessageId,body) {
        
        var service = new HttpService();
        var response;

        response = await service.post(`/api/supportmessages/${supportMessageId}/replies`, body);

        return await response
    }

    



    
}

