import React from 'react'
import { Utils } from '../../../../utils'
import TranslateController from 'controller/shared/TranslateController'

function InstructorAbout({ instructor }) {

    var description = Utils.parseDescription(instructor);


    const { _t } = TranslateController()
    return (
        <>
            {description.length > 1 && (
                <div className='row lan-rtl'>
                    
                    <div className="col-md-8">
                        <section id="about" className="section section-no-border pt-3 pb-3 m-0" >
                            <div className="container lan-text-right lan-kfont">
                                <div className="row  lan-rtl lan-kfont">
                                    <div className="col order-md-1 order-2" >
                                        <p className="font-weight-light pb-2 mb-4 text-justify lan-rtl lan-text-right lan-kfont">
                                            {description}
                                        </p>
                                    </div>
                                    {/* <div className="col-lg-6 order-md-2 order-1 " >
                                    <div className="pl-25 sm-p-0">
                                        <video className='full-width' controls >
                                            <source src={instructor ? instructor.intro_url : "/assets/video/kt.mp4"} type="video/mp4" />
                                        </video>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="col-md-4">

                    </div>
                </div>

            )}</>
    )
}

export default InstructorAbout