import React, { useEffect, useState } from "react";
import Select from "react-select";
import { UserHttpService } from "services/user";

export default function SelectPermission({ callback, value, width, name = "permission" }) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "white",
      width: width || "200px",
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (base) => ({
      ...base,
      width: "200px",
      '*::-webkit-scrollbar': {
        width: "0.4em",
      },
      '*::-webkit-scrollbar-track': {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: "#c7c7c7",
      },
      borderRadius: 0,
      marginTop: 0,
      backgroundColor: "#fff",
      opacity: "0.97",
    }),
    menuList: (base) => ({
      ...base,
      padding: 10,
    }),
    option: (base, state) => {
      let backgroundColor = "transparent";
      if (state.isSelected || state.isFocused || state.isActive) {
        backgroundColor = "#ccc";
      }
      return {
        ...base,
        color: "#000",
        backgroundColor,
        transition: "all 0.5s ease-out",
      };
    },
  };

  useEffect(() => {
    setLoading(true);
    UserHttpService.loadRoles()
      .then((roles) => {
        const formattedOptions = roles.results.map((role) => ({
          label: role.name, // Adjust based on your API response
          value: role.id,
        }));
        setOptions(formattedOptions);
        setLoading(false);
      })
      .catch(() => {
        setOptions([]);
        setLoading(false);
      });
  }, []);

  const handleSelect = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : null;
    callback(selectedValue, name);
  };

  return (
    <div className="filter-select">
      <Select
        options={options}
        styles={customStyles}
        onChange={handleSelect}
        placeholder={loading ? "Loading..." : "Select Permission"}
        value={options.find((option) => option.value === value) || null}
        isLoading={loading}
      />
    </div>
  );
}
