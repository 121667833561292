import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { Utils } from 'utils';
import { PostHttpService } from 'view/cms/features/feature_blog/services';

function BlogStyle5({ categoryId, category, query, size = 6 }) {


    const [posts, setPosts] = useState(null);


    const { isLoading, error, data, refetch } = useQuery(['posts', categoryId], () => { return PostHttpService.loadPosts(categoryId, query,size,1) }, {
        onSuccess: (data) => {
            setPosts(data.results)
        },
        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
        refetchInterval: 600 * 10000,
    });

    useEffect(() => {
        refetch()
    }, [query])




    return (
        <div className="row lan-rtl mb-3">
            {category && (
                
                <Link to={`/blog/${category.id}`} className="heading heading-border heading-middle-border">
                    <h3 className="text-4"><strong className="font-weight-bold text-1 px-3 text-light py-2 bg-quaternary kfont">{category && Utils.getDefaultName(category)}</strong></h3>
                </Link>
            )}
            {posts && posts.map((post, index) => (
                <div key={`blog_post_${post.id}`} class="col-6 col-lg-2 pb-2">
                    <Link to={Utils.getPostUrl(post)}>
                        <article>
                            <div class="thumb-info thumb-info-no-borders thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
                                <div class="thumb-info-wrapper thumb-info-wrapper-opacity-6">
                                    <img src={post.image} class="img-fluid book-list-image" alt={post.title} />
                                    <div class="thumb-info-title bg-transparent p-4 lan-text-right">
                                        <div class="thumb-info-inner mt-1">
                                            <h2 class="text-color-light line-height-2 text-4 font-weight-bold mb-0 lan-rtl lan-text-right">{post.title}</h2>
                                        </div>

                                        <div class="thumb-info-show-more-content">
                                            <div className="thumb-info-type  bg-color-primary px-2 mb-1 kfont">{Utils.getDefaultName(post.category)}</div>
                                            <p class="mb-0  line-height-normal text-size-12 mb-1 mt-2 text-light opacity-5 lan-kfont lan-text-right line-height-normal">{Utils.parse(post.short_description, 15)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </Link>
                </div>
            )
            )}
        </div>
    )
}

export default BlogStyle5