import React from 'react'
import CmsHeader from '../../components/shared/CmsHeader'
import { Link } from 'react-router-dom'
import AdminView, { EditorView } from 'view/cms/components/shared/Permissions'
import PermissionCheck from 'view/cms/components/shared/PermissionCheck'

function Settings() {
  return (
    <div className="body">
      <CmsHeader />

      <div role="main" className="main ">
        <div className="container">
          <div className="box-header mb-5">
            <h5>Settings</h5>

          </div>
          <PermissionCheck permission="setting" showError={1}>

          <div class="row">
            <AdminView>
              <div class="col-6 col-sm-4 col-lg-2">
                <div class="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
                  <div class="featured-box featured-box-no-borders featured-box-box-shadow">
                    <Link to="/cms/settings/general" class="text-decoration-none">
                      <span class="box-content px-1 py-4 text-center d-block">
                        <span class="text-primary text-8 position-relative top-3 mt-3"><i class="fas fa-cog"></i></span>
                        <span class="elements-list-shadow-icon text-default"><i class="fas fa-cog"></i></span>
                        <span class="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">General</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </AdminView>
            <AdminView>
              <div class="col-6 col-sm-4 col-lg-2">
                <div class="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
                  <div class="featured-box featured-box-no-borders featured-box-box-shadow">
                    <Link to="/cms/about" class="text-decoration-none">
                      <span class="box-content px-1 py-4 text-center d-block">
                        <span class="text-primary text-8 position-relative top-3 mt-3"><i class="fas fa-info"></i></span>
                        <span class="elements-list-shadow-icon text-default"><i class="fas fa-info"></i></span>
                        <span class="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">About Items</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </AdminView>
            <AdminView>
              <div class="col-6 col-sm-4 col-lg-2">
                <div class="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
                  <div class="featured-box featured-box-no-borders featured-box-box-shadow">
                    <Link to="/cms/settings/faq" class="text-decoration-none">
                      <span class="box-content px-1 py-4 text-center d-block">
                        <span class="text-primary text-8 position-relative top-3 mt-3"><i class="fas fa-question"></i></span>
                        <span class="elements-list-shadow-icon text-default"><i class="fas fa-question"></i></span>
                        <span class="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">FAQ</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </AdminView>
            <AdminView>
              <div class="col-6 col-sm-4 col-lg-2">
                <div class="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
                  <div class="featured-box featured-box-no-borders featured-box-box-shadow">
                    <Link to="/cms/media/" class="text-decoration-none">
                      <span class="box-content px-1 py-4 text-center d-block">
                        <span class="text-primary text-8 position-relative top-3 mt-3"><i class="fas fa-image"></i></span>
                        <span class="elements-list-shadow-icon text-default"><i class="fas fa-image"></i></span>
                        <span class="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">Media</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </AdminView>
            <AdminView>
              <div class="col-6 col-sm-4 col-lg-2">
                <div class="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
                  <div class="featured-box featured-box-no-borders featured-box-box-shadow">
                    <Link to="/cms/languages" class="text-decoration-none">
                      <span class="box-content px-1 py-4 text-center d-block">
                        <span class="text-primary text-8 position-relative top-3 mt-3"><i class="fas fa-globe"></i></span>
                        <span class="elements-list-shadow-icon text-default"><i class="fas fa-globe"></i></span>
                        <span class="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">Language</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </AdminView>
            <AdminView>
              <div class="col-6 col-sm-4 col-lg-2">
                <div class="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
                  <div class="featured-box featured-box-no-borders featured-box-box-shadow">
                    <Link to="/cms/translations" class="text-decoration-none">
                      <span class="box-content px-1 py-4 text-center d-block">
                        <span class="text-primary text-8 position-relative top-3 mt-3"><i class="fas fa-globe"></i></span>
                        <span class="elements-list-shadow-icon text-default"><i class="fas fa-globe"></i></span>
                        <span class="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">Translations</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </AdminView>
          </div>
          <div class="row">

            <AdminView>
              <div class="col-6 col-sm-4 col-lg-2">
                <div class="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
                  <div class="featured-box featured-box-no-borders featured-box-box-shadow">
                    <Link to="/cms/users" class="text-decoration-none">
                      <span class="box-content px-1 py-4 text-center d-block">
                        <span class="text-primary text-8 position-relative top-3 mt-3"><i class="fas fa-user"></i></span>
                        <span class="elements-list-shadow-icon text-default"><i class="fas fa-user"></i></span>
                        <span class="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">Users</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

            </AdminView>
            <AdminView>
              <div class="col-6 col-sm-4 col-lg-2">
                <div class="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
                  <div class="featured-box featured-box-no-borders featured-box-box-shadow">
                    <Link to="/cms/roles" class="text-decoration-none">
                      <span class="box-content px-1 py-4 text-center d-block">
                        <span class="text-primary text-8 position-relative top-3 mt-3"><i class="fas fa-tasks"></i></span>
                        <span class="elements-list-shadow-icon text-default"><i class="fas fa-tasks"></i></span>
                        <span class="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">Roles</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

            </AdminView>
            
            <AdminView>
              <div class="col-6 col-sm-4 col-lg-2">
                <div class="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
                  <div class="featured-box featured-box-no-borders featured-box-box-shadow">
                    <Link to="/cms/instructor/" class="text-decoration-none">
                      <span class="box-content px-1 py-4 text-center d-block">
                        <span class="text-primary text-8 position-relative top-3 mt-3"><i class="fas fa-user"></i></span>
                        <span class="elements-list-shadow-icon text-default"><i class="fas fa-user"></i></span>
                        <span class="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">People</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </AdminView>
            
            <EditorView>
              <div class="col-6 col-sm-4 col-lg-2">
                <div class="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
                  <div class="featured-box featured-box-no-borders featured-box-box-shadow">
                    <Link to="/cms/dictionary" class="text-decoration-none">
                      <span class="box-content px-1 py-4 text-center d-block">
                        <span class="text-primary text-8 position-relative top-3 mt-3"><i class="fas fa-list"></i></span>
                        <span class="elements-list-shadow-icon text-default"><i class="fas fa-list"></i></span>
                        <span class="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">Dictionary</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </EditorView>
            
            
            
            <AdminView>
              <div class="col-6 col-sm-4 col-lg-2">
                <div class="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
                  <div class="featured-box featured-box-no-borders featured-box-box-shadow">
                    <Link to="/cms/support/" class="text-decoration-none">
                      <span class="box-content px-1 py-4 text-center d-block">
                        <span class="text-primary text-8 position-relative top-3 mt-3"><i class="fas fa-envelope"></i></span>
                        <span class="elements-list-shadow-icon text-default"><i class="fas fa-envelope"></i></span>
                        <span class="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">Support Messages</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </AdminView>
          </div>

          </PermissionCheck>

        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default Settings